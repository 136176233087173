.App {
  text-align: center;
}

html body{
  height: 100vh;
  background-color: lavender;
}
.FullLogo{
  margin-left: 50px;
}
.mainbuttons{

   margin-left: 7vw;
}

.loginButtons{
  margin-left: auto;
  margin-right: 10px;
}

.btn2:hover {color: blue;}
.btn2{
  margin-left: 15px;
  background-color: inherit;
}

.btn:hover {color: blue;}
.btn {
  font-weight: bold;
  border: none;
  background-color: inherit;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}
.HeaderBar{
  height: 12vh;
  background-color: white;
  display: flex;
  align-items: center;
}
.RadialPhone{
  margin-left: 25px;
  padding: 5px;
  background: #02023626;
  border-radius: 11px;
}
.contactText{
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
}
.socialBar{

  height: 5vh;  background: #f6f7fb;
  display: flex;
  align-items: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.grid-container {
  margin-left: 5vw;
  margin-right: 5vw;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
  background-color: #2196F3;
  padding: 10px;
}
.Sold{
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  background-color: red;
  height: 20%;
}
.PendingSale{
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  background-color: yellow;
  height: 20%;

}
.forSale{
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  background-color: forestgreen;
  height: 20%;

}

.gridPhoto{
  width: 100%;
  height: 80%;
  display: block;
  object-fit: cover;
}
.gridItem{
  width: 20vw;
  height: 25vh;
  border-style: solid;
  border-width: 5px;
  border-color: black;
}

.Portrait{
  text-align: center;
  border-style: solid;
  height: 100vh;
  width: 100vw;

}
.centered3{
  font-size: 23px;
  color: white;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered2{
  font-size: 42px;
  color: white;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered {
  font-size: 42px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.stickyHeader{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.PhoneContact{
text-align: center;
  font-weight: bold;
  font-size: 22px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
